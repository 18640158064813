@import './../../../assets/css/custom.scss';

.breadcrumbs {
  > * {
    .breadcrumb {
      // display: inline-block;
      height: 36px;
      display: inline-flex;
      align-items: center;
      position: relative;
      margin-top: 0.25em;
      margin-bottom: 0.25em;
      margin-inline-end: 1em;
      padding: 0.5em 0.5em;
      background-color: #e6e6e6;
      white-space: nowrap;
      box-shadow: 0 0.125rem 0.25rem #00000013 !important;
      &.active,
      &:hover {
        color: #fff;
        background-color: $primary-color;
        white-space: nowrap;
        box-shadow: 0.25rem 0.125rem 0.3rem #c4a46548 !important;

        &:after {
          border-color: transparent transparent transparent $primary-color;
        }
        &:before {
          border-color: $primary-color $primary-color $primary-color transparent;
          box-shadow: 0.25rem 0.1rem 0.3rem #c4a46548;
        }
      }
      span {
        margin-inline-start: 5px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        // height: 100%;
        border-width: 1.3em 0.5em 1.3em 1em;
        border-style: solid;
        border-color: transparent transparent transparent #e6e6e6;
      }
    }
  }
  > ol > * + * {
    .breadcrumb {
      margin-inline-start: 1em;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        right: 100%;
        border-width: 1.27em 0.5em 1.27em 1em;
        border-style: solid;
        border-color: #e6e6e6 #e6e6e6 #e6e6e6 transparent;
        box-shadow: 0.25rem 0rem 0.25rem 0px #e6e6e6;
      }
    }
  }
}

:global(.rtl) {
  .breadcrumbs {
    > * {
      .breadcrumb {
        &:after {
          left: unset;
          right: 100%;
          border-width: 1.3em 1em 1.3em 0.5em;
          border-style: solid;
          border-color: transparent #e6e6e6 transparent transparent;
        }

        &.active,
        &:hover {
          &:after {
            border-color: transparent $primary-color transparent transparent;
          }
          &:before {
            border-color: $primary-color transparent $primary-color
              $primary-color;
            box-shadow: -0.25rem 0.1rem 0.3rem #c4a46548;
          }
        }
      }
    }
    > ol > * + * {
      .breadcrumb {
        &:before {
          right: unset;
          left: 100%;
          border-width: 1.27em 1em 1.27em 0.5em;
          border-color: #e6e6e6 transparent #e6e6e6 #e6e6e6;
          box-shadow: -0.25rem 0rem 0.25rem 0px #e6e6e6;
        }
      }
    }
  }
}
