
.shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #c0c0c0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(116, 116, 116);
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 4px;
}

.full-size-carousel .ant-carousel .slick-slide {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.full-size-carousel .ant-carousel .slick-slide img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Adjust this as needed */
}