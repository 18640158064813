.centeredContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* This makes the div take the full viewport height */
    text-align: center;
 
    background-image: url('../../../../assets/images/gold-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }