.logo {
  float: left;
  margin: 6px 0;
}
:global(.rtl) {
  .logo {
    float: right;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  padding: 0 !important;
}

.menu {
  justify-content: flex-end;
}

.authUserSubMenu {
  display: flex;
  align-items: center;
}

.authUserSettings {
  min-width: 350px;
  margin: 0 !important;
  height: auto !important;
  background-color: #fff;
  cursor: auto !important;
  background-color: transparent !important;
  padding: 0 !important
}
